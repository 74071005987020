import React, { useState, useEffect } from "react";
import { Image, Form, Button, InputGroup, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DurationTimer from "./DurationTimer";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgoraRTC from "../../hooks/useAgoraRTC";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import {
  fetchEventStart,
  endEventStart,
  fetchUserDetailsStart,
} from "../../store/actions/PrivateCallAction";
import "./MeetingRoom.css";
import AgoraAudioCall from "./AgoraAudioCall";
import AgendaModal from "./AgendaModal";
import io from "socket.io-client";
import api from "../../Environment";
import {
  doc,
  collection,
  updateDoc,
  getDocs,
  getFirestore,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { async } from "@firebase/util";

const rtcclient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
let callSocket;

const MeetingRoomIndex = (props) => {
  const [activeAgoraUsers, setActiveAgoraUsers] = useState([]);

  const [agenda, setAgenda] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const closeAgendaModal = () => {
    setAgenda(false);
  };

  const [showSidebar, setShowSidebar] = useState(false);

  const {
    localAudioTrack,
    leaveRtcChannel,
    joinAudio,
    joinState,
    remoteUsers,
    activeUsers,
    isStreamEnded,
    muteAudio,
    mediaStatus,
  } = useAgoraRTC(rtcclient);

  useEffect(() => {
    props.dispatch(
      fetchEventStart({
        event_unique_id: props.match.params.event_unique_id,
      })
    );
  }, []);

  const [handraiseUserIds, setHandraiseUserIds] = useState([]);

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     props.dispatch(
  //       endEventStart({
  //         event_unique_id: props.match.params.event_unique_id,
  //       })
  //     );
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   window.addEventListener('popstate', unloadCallback);
  //   return () => {
  //     window.removeEventListener("beforeunload", unloadCallback);
  //     window.removeEventListener("popstate", unloadCallback);
  //   };
  // }, []);

  useEffect(() => {
    if (!props.singleEvent.loading) {
      handleJoin();
      callSocketConnect();
    }
  }, [props.singleEvent.loading]);

  const fetchPost = async () => {
    await getDocs(collection(db, props.match.params.event_unique_id)).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log(newData);
      }
    );
  };

  const [apiCallStatus, setApiCallStatus] = useState(false);

  const callSocketConnect = () => {
    // check the socket url is configured
    let socketUrl = configuration.get("configData.chat_socket_url");
    if (socketUrl) {
      callSocket = io(socketUrl, {
        query:
          `commonid:'event_id_${props.match.params.event_unique_id}',myid:` +
          props.match.params.event_unique_id,
      });

      callSocket.emit("update sender", {
        commonid: `event_id_${props.match.params.event_unique_id}`,
      });

      callSocket.on("onuser handraise", (newData) => {
        console.log(newData);
        if (newData.is_hand_raised == 1) {
          console.log(newData.is_hand_raised);
          setHandraiseUserIds((handraiseUserIds) => [
            ...handraiseUserIds,
            Number(newData.agora_user_id),
          ]);
          console.log("handraiseUserIds", handraiseUserIds);
        } else {
          setHandraiseUserIds((prevUserIds) =>
            prevUserIds.filter(
              (userId) => userId !== Number(newData.agora_user_id)
            )
          );
        }
      });
    }
  };
  const muteAll = (event) => {
    event.preventDefault();
    const query = collection(db, props.match.params.event_unique_id);
    getDocs(query).then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        updateDoc(doc.ref, { isMute: true });
      });
    });
  };

  const unMuteParticipant = async (event, userId) => {
    event.preventDefault();
    const data = {
      isSpeaker: true,
      isMute: false,
    };
    const index = handraiseUserIds.indexOf(Number(userId));
    if (index > -1) {
      // only splice array when item is found
      handraiseUserIds.splice(index, 1); // 2nd parameter means remove one item only
    }
    setHandraiseUserIds(handraiseUserIds);

    const db = getFirestore();
    const docRef = doc(db, props.match.params.event_unique_id, String(userId));
    await updateDoc(docRef, data)
      .then((docRef) => {
        console.log(
          "A New Document Field has been added to an existing document"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const muteParticipant = async (event, userId) => {
    event.preventDefault();
    const data = {
      isMute: true,
    };
    const db = getFirestore();
    const docRef = doc(db, props.match.params.event_unique_id, String(userId));
    await updateDoc(docRef, data)
      .then((docRef) => {
        console.log(
          "A New Document Field has been added to an existing document"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const acceptHandRaise = async (event, userId) => {
    event.preventDefault();

    const data = {
      isSpeaker: true,
      isMute: false,
      isHandRaised: false,
    };
    const db = getFirestore();
    const docRef = doc(db, props.match.params.event_unique_id, String(userId));
    await updateDoc(docRef, data)
      .then((docRef) => {
        console.log(
          "A New Document Field has been added to an existing document"
        );
      })
      .catch((error) => {
        console.log(error);
      });
    const index = handraiseUserIds.indexOf(Number(userId));
    if (index > -1) {
      // only splice array when item is found
      handraiseUserIds.splice(index, 1); // 2nd parameter means remove one item only
    }
    setHandraiseUserIds(handraiseUserIds);
  };

  const removeParticipant = async (event, userId) => {
    event.preventDefault();
    const db = getFirestore();
    await deleteDoc(
      doc(db, props.match.params.event_unique_id, String(userId))
    );
  };

  useEffect(() => {
    // fetchPost();

    if (remoteUsers) {
      setActiveAgoraUsers(remoteUsers);
      remoteUsers.forEach((user) => {
        api
          .postMethod("user_details", { user_id: user.uid })
          .then((response) => {
            if (response.data.success) {
              user.user_name = response.data.data.user_name;
              user.user_picture = response.data.data.user_picture;
              setActiveAgoraUsers((activeAgoraUsers) =>
                activeAgoraUsers.map((usr) =>
                  usr.uid === user.uid ? user : usr
                )
              );
            }
          });
      });
    }
  }, [remoteUsers]);
  console.log(handraiseUserIds);
  const [skipRenderRes, setSkipRenderRes] = useState(true);

  const leaveCall = () => {
    if (window.confirm("are you sure to leave call?")) {
      leaveRtcChannel();
      props.dispatch(
        endEventStart({
          event_unique_id: props.match.params.event_unique_id,
        })
      );
    } else {
    }
  };

  useEffect(() => {
    if (
      !skipRenderRes &&
      !props.endEvent.loading &&
      Object.keys(props.endEvent.data).length > 0
    ) {
      const timeout = setTimeout(() => {
        window.location.replace(props.endEvent.data.redirection_url);
      }, 3000);

      return () => clearTimeout(timeout);
    }
    setSkipRenderRes(false);
  }, [props.endEvent]);

  const handleJoin = () => {
    joinAudio(
      configuration.get("configData.agora_app_id"),
      props.singleEvent.data.event.virtual_id,
      props.singleEvent.data.event.agora_token
        ? props.singleEvent.data.event.agora_token
        : null
    );
  };

  if (props.singleEvent.loading) {
    return null;
  }

  const getParticipant = (user) => {
    return (
      <li>
        <div className="meeting-room-participate-card">
          <div className="meeting-room-particpate-left-sec">
            <div className="meeting-room-participate-img-sec">
              <Image
                className="meeting-room-participate-img"
                src={
                  user.user_picture ??
                  window.location.origin + "/img/placeholder.png"
                }
              />
            </div>
            <div className="meeting-room-participate-info">
              <h4>{user.user_name ?? ""}</h4>
            </div>
          </div>
          <div className="meeting-room-particpate-right-sec">
            {handraiseUserIds.includes(user.uid) ? (
              <Button className="participate-hand-raise-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#4839DB"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M22.943 5.699a3.017 3.017 0 00-2.439-.674C19.076 5.257 18 6.575 18 8.092v.939l-7.218-7.218a2.724 2.724 0 00-2.154-.792 2.74 2.74 0 00-2.167 1.395 2.738 2.738 0 00-3.948.536 2.684 2.684 0 00-.444 2.049A2.755 2.755 0 00.674 6.017c-.771 1.069-.616 2.611.359 3.586l.344.344a2.765 2.765 0 00-.884.793c-.769 1.069-.615 2.611.35 3.576l6.723 6.918a9.052 9.052 0 006.457 2.763h.084a9.05 9.05 0 006.421-2.647l.815-.81a9.059 9.059 0 002.658-6.386v-6.17c0-.881-.385-1.714-1.057-2.286zM22 14.155a7.045 7.045 0 01-2.067 4.967l-.815.81a7.174 7.174 0 01-5.078 2.066 7.066 7.066 0 01-5.041-2.157l-6.732-6.929c-.285-.285-.351-.726-.151-1.003a.735.735 0 011.123-.1L7.53 16.1l1.414-1.414-6.497-6.497c-.284-.285-.35-.725-.15-1.003a.733.733 0 011.122-.101l6.562 6.562 1.414-1.414-7.109-7.109c-.284-.285-.35-.725-.15-1.003a.735.735 0 011.122-.1l7.175 7.175 1.414-1.414-5.451-5.451c-.285-.285-.351-.726-.15-1.003a.735.735 0 011.122-.1L20 13.86V8.092c0-.538.362-1.018.825-1.093.302-.047.595.031.823.224.224.19.353.468.353.762v6.17zM13.719 1.959l.562-1.918c2.123.622 3.528 2.025 4.176 4.17l-1.915.578c-.446-1.478-1.37-2.403-2.824-2.83zM4.781 20.963l-.562 1.92C2.095 22.261.69 20.858.042 18.712l1.915-.578c.446 1.479 1.37 2.404 2.823 2.829z"></path>
                </svg>
              </Button>
            ) : null}
            {user.audioTrack ? (
              <Button className="particpate-mute-audio-btn">
                <Image
                  className="particpate-mute-audio-icon"
                  src={
                    window.location.origin + "/img/action-mute-icon-1-blue.svg"
                  }
                />
              </Button>
            ) : (
              <Button className="particpate-mute-audio-btn">
                <Image
                  className="particpate-mute-audio-icon"
                  src={
                    window.location.origin + "/img/action-unmute-icon-1-red.svg"
                  }
                />
              </Button>
            )}

            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="more-action-btn"
              >
                <Image
                  className="meeting-room-admin-action-icon"
                  src={window.location.origin + "/img/three-dots.svg"}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(event) => removeParticipant(event, user.uid)}
                >
                  Remove
                </Dropdown.Item>
                {user?._audio_muted_ ? (
                  ""
                ) : (
                  <Dropdown.Item
                    onClick={(event) => muteParticipant(event, user.uid)}
                  >
                    Mute
                  </Dropdown.Item>
                )}
                {handraiseUserIds.includes(user.uid) ? (
                  <Dropdown.Item
                    onClick={(event) => acceptHandRaise(event, user.uid)}
                  >
                    Accept Handraise
                  </Dropdown.Item>
                ) : user?._audio_muted_ ? (
                  <Dropdown.Item
                    onClick={(event) => unMuteParticipant(event, user.uid)}
                  >
                    UnMute
                  </Dropdown.Item>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      <div className="meeting-room-sec">
        <div className="meeting-room-header-sec">
          <div className="meeting-room-logo-sec">
            <Image
              className="meeting-room-logo"
              src={window.location.origin + "/img/cityplot-logo.png"}
            />
            <div
              className="mobile-menu-sec"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                fill="#4839DB"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
              </svg>
            </div>
          </div>
          <div className="meeting-details-card">
            <div className="meeting-info-item">
              <div className="meeting-info-content-card">
                <h4>{props.singleEvent.data.event.title}</h4>
                <p>
                  {props.singleEvent.data.event.stream_date} |{" "}
                  {props.singleEvent.data.event.stream_start_time}
                </p>
              </div>
              <div className="meeting-room-agenda-sec">
                <Button className="agenda-btn" onClick={() => setAgenda(true)}>
                  View Agenda
                </Button>
              </div>
            </div>
            <div className="meeting-user-details">
              <div className="meeting-user-img-sec">
                <Image
                  className="meeting-user-img"
                  src={props.singleEvent.data.event.admin.picture}
                />
              </div>
              <div className="meeting-user-info">
                <h4>{props.singleEvent.data.event.admin.name}</h4>
                <p>Host</p>
              </div>
            </div>
          </div>
        </div>
        <div className="meeting-room-body-sec">
          <div className="meeting-room-body-left-sec">
            <div className="meeting-room-content-sec">
              <p className="desc-meeting-info">
                {showMore ? (
                  <p
                    className="desc-meeting-info"
                    dangerouslySetInnerHTML={{
                      __html: props.singleEvent.data.event.description,
                    }}
                  ></p>
                ) : (
                  <p
                    className="desc-meeting-info"
                    dangerouslySetInnerHTML={{
                      __html:
                        props.singleEvent.data.event.description_formatted,
                    }}
                  ></p>
                )}
                <Link
                  to="#"
                  className="view-more-btn"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? "Show less" : "Show more"}
                </Link>
              </p>
            </div>
            <div className="meeting-room-admin-card">
              <Image
                className="meeting-room-admin-img"
                src={props.singleEvent.data.event.picture}
              />
              <div className="meeting-admin-timer">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#fff"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.707 16.325a1 1 0 00-1.414 0l-5.627 5.628-2.688-2.653a1 1 0 00-1.435 1.4l2.744 2.7a1.876 1.876 0 001.345.6h.033A1.873 1.873 0 0018 23.447l5.707-5.708a1 1 0 000-1.414z"></path>
                  <path d="M11.09 21.959A10 10 0 1122 12c0 .307-.015.611-.041.911A1 1 0 0022.866 14a.989.989 0 001.085-.907c.032-.363.049-.726.049-1.093a12 12 0 10-13.09 11.951h.091a1 1 0 00.089-2z"></path>
                  <path d="M11 7v4.586l-2.707 2.707a1 1 0 101.414 1.414l3-3A1 1 0 0013 12V7a1 1 0 00-2 0z"></path>
                </svg> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#fff"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.707 16.325a1 1 0 00-1.414 0l-5.627 5.628-2.688-2.653a1 1 0 00-1.435 1.4l2.744 2.7a1.876 1.876 0 001.345.6h.033A1.873 1.873 0 0018 23.447l5.707-5.708a1 1 0 000-1.414z"></path>
                  <path d="M11.09 21.959A10 10 0 1122 12c0 .307-.015.611-.041.911A1 1 0 0022.866 14a.989.989 0 001.085-.907c.032-.363.049-.726.049-1.093a12 12 0 10-13.09 11.951h.091a1 1 0 00.089-2z"></path>
                  <path d="M11 7v4.586l-2.707 2.707a1 1 0 101.414 1.414l3-3A1 1 0 0013 12V7a1 1 0 00-2 0z"></path>
                </svg>
                <span>
                  <DurationTimer
                    time={props.singleEvent.data.event.started_at}
                  />
                </span>
              </div>
              {/* <div className="meeting-admin-full-screen">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#fff"
                  viewBox="0 0 24 24"
                >
                  <path d="M22.5 15.5A1.5 1.5 0 0021 17v1.5a2.5 2.5 0 01-2.5 2.5H17a1.5 1.5 0 000 3h1.5a5.506 5.506 0 005.5-5.5V17a1.5 1.5 0 00-1.5-1.5zM7 0H5.5A5.506 5.506 0 000 5.5V7a1.5 1.5 0 003 0V5.5A2.5 2.5 0 015.5 3H7a1.5 1.5 0 000-3zM7 21H5.5A2.5 2.5 0 013 18.5V17a1.5 1.5 0 00-3 0v1.5A5.506 5.506 0 005.5 24H7a1.5 1.5 0 000-3zM18.5 0H17a1.5 1.5 0 000 3h1.5A2.5 2.5 0 0121 5.5V7a1.5 1.5 0 003 0V5.5A5.506 5.506 0 0018.5 0z"></path>
                </svg>
              </div> */}
              <div className="meeting-admin-name">
                <span>{props.singleEvent.data.event.admin.name}</span>
              </div>
              {/* <div className="meeting-admin-audio-speech">
                <Image
                  className="meeting-admin-audio-img"
                  src={
                    window.location.origin + "/img/sound.png"
                  }
                />
              </div> */}
            </div>
            <div className="meeting-room-user-box">
              {activeAgoraUsers.length > 0 ? (
                <>
                  {activeAgoraUsers.map((user, index) => (
                    <AgoraAudioCall
                      key={index}
                      audioTrack={user.audioTrack}
                      user={user}
                    />
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
            <div className="meeting-room-admin-action-btn-sec">
              <ul className="list-unstyled meeting-room-admin-action-btn">
                <li className="bg-mute">
                  {joinState && (
                    <a onClick={() => muteAudio()}>
                      {mediaStatus.audio.muted ? (
                        <Image
                          className="meeting-room-admin-action-icon"
                          src={
                            window.location.origin +
                            "/img/action-unmute-icon-1.svg"
                          }
                        />
                      ) : (
                        <Image
                          className="meeting-room-admin-action-icon"
                          src={
                            window.location.origin +
                            "/img/action-mute-icon-1.svg"
                          }
                        />
                      )}
                    </a>
                  )}
                </li>
                <li className="bg-action" onClick={() => leaveCall()}>
                  <a href="#">
                    {/* <Image
                      className="meeting-room-admin-action-icon"
                      src={
                        window.location.origin + "/img/three-dots.svg"
                      }
                    /> */}
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="34"
                      fill="none"
                      viewBox="0 0 34 34"
                    >
                      <g clipPath="url(#clip0_24_3429)">
                        <path
                          fill="#fff"
                          fillRule="evenodd"
                          d="M16.968 16.306c-5.641 0-3.019 3.903-6.61 3.904-3.462 0-4.804.649-4.803-3.738.054-.496-.857-4.898 11.413-4.9 12.27-.002 11.363 4.401 11.417 4.897 0 4.398-1.342 3.738-4.804 3.738-3.592 0-.973-3.902-6.613-3.901z"
                          clipRule="evenodd"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_24_3429">
                          <path
                            fill="#fff"
                            d="M0 0H24V24H0z"
                            transform="rotate(135 13.456 15.515)"
                          ></path>
                        </clipPath>
                      </defs>
                    </svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="34"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M21.3 15.38a1.378 1.378 0 01-1.5.572l-2.724-.7a1.886 1.886 0 01-1.38-2.2l.249-1.228a11.426 11.426 0 00-7.86-.006l.242 1.238a1.887 1.887 0 01-1.387 2.196l-2.74.693a1.383 1.383 0 01-1.488-.566A4.579 4.579 0 014.1 9.072a16.039 16.039 0 0115.792.013A4.584 4.584 0 0121.3 15.38z"
                        data-original="#000000"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
              <Button
                className="end-call-btn"
                onClick={(event) => muteAll(event)}
              >
                <div className="mute-all-bg">
                  {/* <Image
                    className="meeting-user-unmute-all-icon"
                    src={
                      window.location.origin + "/img/unmute-icon-new.svg"
                    }
                  /> */}
                  <Image
                    className="meeting-user-unmute-all-icon"
                    src={
                      window.location.origin + "/img/action-unmute-icon-1.svg"
                    }
                  />
                </div>
                Mute All
              </Button>
            </div>
          </div>
          <div
            className={`meeting-room-body-right-sec ${
              showSidebar ? "mobile" : ""
            }`}
          >
            <div
              className="close-mobile"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#4839DB"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M19 2H5C2.243 2 0 4.243 0 7v10c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5V7c0-2.757-2.243-5-5-5zm3 15c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V7c0-1.654 1.346-3 3-3h14c1.654 0 3 1.346 3 3v10zm-5.793-7.793L13.414 12l2.793 2.793a.999.999 0 11-1.414 1.414L12 13.414l-2.793 2.793a.997.997 0 01-1.414 0 .999.999 0 010-1.414L10.586 12 7.793 9.207a.999.999 0 111.414-1.414L12 10.586l2.793-2.793a.999.999 0 111.414 1.414z"></path>
              </svg>
            </div>

            {activeAgoraUsers.length > 0 ? (
              <>
                <div className="meeting-room-participate-header-sec">
                  <h3>
                    Participants - <span>({activeAgoraUsers.length})</span>
                  </h3>
                </div>
                <div className="meeting-room-participate-list-sec">
                  <ul className="meeting-room-participate-list list-unstyled">
                    <>
                      {activeAgoraUsers
                        .filter((user) => handraiseUserIds.includes(user.uid))
                        .map((user) => getParticipant(user))}
                      {activeAgoraUsers
                        .filter((user) => !handraiseUserIds.includes(user.uid))
                        .map((user) => getParticipant(user))}
                    </>
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="meeting-room-participate-header-sec">
                  <h3>
                    Participants - <span>(0)</span>
                  </h3>
                </div>
                <div className="no-particpate-img-sec">
                  <Image
                    className="no-particpate-img"
                    src={
                      window.location.origin +
                      "/img/participate/no-particpate-joined.svg"
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <AgendaModal
        agenda={agenda}
        closeAgendaModal={closeAgendaModal}
        agendaDetails={props.singleEvent.data.event.agenda}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singleEvent: state.privateCall.singleEvent,
  endEvent: state.privateCall.endEvent,
  eventUsers: state.privateCall.eventUsers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(MeetingRoomIndex));

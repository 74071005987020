import {
  FETCH_TICKET_DETAILS_START,
  FETCH_TICKET_DETAILS_FAILURE,
  FETCH_TICKET_DETAILS_SUCCESS,
  END_TICKET_CALL_SUCCESS,
  END_TICKET_CALL_FAILURE,
  END_TICKET_CALL_START,
  FETCH_EVENT_DETAILS_START,
  FETCH_EVENT_DETAILS_SUCCESS,
  FETCH_EVENT_DETAILS_FAILURE,
  END_EVENT_START,
  END_EVENT_SUCCESS,
  END_EVENT_FAILURE,
  FETCH_EVENT_USERS_START,
  FETCH_EVENT_USERS_SUCCESS,
  FETCH_EVENT_USERS_FAILURE,
  FETCH_MORE_EVENT_USERS_START,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {

  singleTicket: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  endTicket: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  singleEvent: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  endEvent: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  eventUsers: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  userDetails: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const PostReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_TICKET_DETAILS_START:
      return {
        ...state,
        singleTicket: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        singleTicket: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_TICKET_DETAILS_FAILURE:
      return {
        ...state,
        singleTicket: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

    case END_TICKET_CALL_START:
      return {
        ...state,
        endTicket: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case END_TICKET_CALL_SUCCESS:
      return {
        ...state,
        endTicket: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case END_TICKET_CALL_FAILURE:
      return {
        ...state,
        endTicket: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_EVENT_DETAILS_START:
      return {
        ...state,
        singleEvent: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        singleEvent: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_EVENT_DETAILS_FAILURE:
      return {
        ...state,
        singleEvent: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case END_EVENT_START:
      return {
        ...state,
        endEvent: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case END_EVENT_SUCCESS:
      return {
        ...state,
        endEvent: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case END_EVENT_FAILURE:
      return {
        ...state,
        endEvent: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_EVENT_USERS_START:
      return {
        ...state,
        eventUsers: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_EVENT_USERS_SUCCESS:
      return {
        ...state,
        eventUsers: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_EVENT_USERS_FAILURE:
      return {
        ...state,
        eventUsers: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_MORE_EVENT_USERS_START:
      return state;
    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        userDetails: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetails: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    default:
      return state;
  }
};

export default PostReducer;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./VideoAudioCall.css";
import { Link, useHistory } from "react-router-dom";
import {
  fetchTicketDetailsStart,
  endTicketCallStart,
} from "../../store/actions/PrivateCallAction";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgoraRTC from "../../hooks/useAgoraRTC";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import AgoraMediaPlayer from "../helper/AgoraMediaPlayer";
import io from "socket.io-client";

import { createNotification } from "react-redux-notify";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";

let callSocket;

const rtcclient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const AudioCallIndex = (props) => {
  const history = useHistory();

  const {
    localAudioTrack,
    leaveRtcChannel,
    joinAudio,
    joinState,
    remoteUsers,
    isStreamEnded,
    muteAudio,
    mediaStatus,
  } = useAgoraRTC(rtcclient);

  useEffect(() => {
    props.dispatch(
      fetchTicketDetailsStart({
        ticket_unique_id:props.match.params.ticket_unique_id,
      })
    );
  }, []);

  useEffect(() => {
    if (!props.singleTicket.loading) {
      handleJoin();
      chatSocketConnect();
    }
  }, [props.singleTicket.loading]);

  const [skipRender, setSkipRender] = useState({
    initial: true,
    completed: false,
  });

  const [skipRenderRes, setSkipRenderRes] = useState(true);

  const [skip, setSkip] = useState(true);

  const chatSocketConnect = () => {
    // check the socket url is configured
    let socketUrl = configuration.get("configData.chat_socket_url");
    console.log(socketUrl);
    if (socketUrl && props.singleTicket.data.ticket) {
     
      callSocket = io(socketUrl, {
        query: `commonid:'user_id_${props.singleTicket.data.ticket.user_id}_ticket_id_${props.singleTicket.data.ticket.ticket_id}',myid:`+props.singleTicket.data.ticket.user_id,
      });

      callSocket.emit("agora call update sender", {
        commonid: `user_id_${props.singleTicket.data.ticket.user_id}_ticket_id_${props.singleTicket.data.ticket.ticket_id}`,
      });

      callSocket.on("end agora call", (newData) => {
        console.log(newData);
        props.dispatch(
          endTicketCallStart({
            ticket_unique_id: props.match.params.ticket_unique_id,
          })
        );
      });

    }
  };


  const handleJoin = () => {
    joinAudio(
      configuration.get("configData.agora_app_id"),
      props.singleTicket.data.ticket.virtual_id,
      props.singleTicket.data.ticket.agora_token
        ? props.singleTicket.data.ticket.agora_token
        : null
    );
  };

  const leaveCall = () => {
    if (window.confirm("are you sure to leave call?")) {
      props.dispatch(
        endTicketCallStart({
          ticket_unique_id: props.match.params.ticket_unique_id,
        })
      );
      leaveRtcChannel();
    } else {
    }
  };

  useEffect(() => {
    if (!skipRenderRes && !props.endTicket.loading && Object.keys(props.endTicket.data).length > 0) {
      
      const timeout = setTimeout(() => {
        window.location.replace(props.endTicket.data.redirection_url);
      }, 3000);
  
      return () => clearTimeout(timeout);

    }
    setSkipRenderRes(false);
  }, [props.endTicket]);

  useEffect(() => {
    if (isStreamEnded) {
      props.dispatch(
        endTicketCallStart({
          ticket_unique_id: props.match.params.ticket_unique_id,
        })
      );
    }
  }, [isStreamEnded]);

  console.log(isStreamEnded);
  
  if (props.singleTicket.loading) {
    return null;
  }

  return (
    <>
      <div className="audio-call-sec">
        
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col md={12} lg={12} className="p-0">
                <div className="audio-call-box">
                  <div className="audio-call-card-center">
                    <div className="audio-call-card">
                      <div className="audio-call-bg"></div>
                      {joinState && (
                        <div className="audio-call-action-btn-sec">
                          <ul className="list-unstyled audio-call-action-btn">
                            <Media as="li">
                              <Button
                                className="btn-link"
                                onClick={() => muteAudio()}
                              >
                                {mediaStatus.audio.muted ? (
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/video-call/mic-off.svg"
                                    }
                                    alt="action-icons"
                                    className="action-icon-img"
                                  />
                                ) : (
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/video-call/mic.svg"
                                    }
                                    className="action-icon-img"
                                  />
                                )}
                              </Button>
                            </Media>
                            <Media as="li" className="call-animation">
                              <Button
                                className="btn-link"
                                onClick={() => leaveCall()}
                              >
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/video-call/end-call.svg"
                                  }
                                  alt="action-icons"
                                  className="action-icon-img"
                                />
                              </Button>
                            </Media>
                          </ul>
                        </div>
                      )}

                      
                      <div className="audio-call-connected-box">
                        <div className="audio-call-connected-left-sec">
                          <Image
                            className="audio-call-user-img"
                            src={props.singleTicket.data.ticket.user.picture}
                          />
                          <h4>
                            {props.singleTicket.data.ticket.user.name}
                          </h4>
                          {/* {joinState && (
                          <AgoraMediaPlayer
                            audioTrack={localAudioTrack}
                            useClassname="d-none"
                          ></AgoraMediaPlayer>
                        )} */}
                        </div>
                        <div className="audio-call-connected-center-sec">
                          <div className="connected-status">
                            <p>
                              {remoteUsers.length > 0
                                ? "Connected"
                                : "Connecting"}
                            </p>
                          </div>
                          <div className="audio-call-connected-icon-sec">
                            <Image
                              className="audio-call-connected-icon"
                              src={
                                window.location.origin +
                                "/assets/images/video-call/connected-arrow.svg"
                              }
                            />
                          </div>
                        </div>
                        <div className="audio-call-connected-right-sec">
                          <Image
                            className="audio-call-user-img"
                            src={
                              props.singleTicket.data.ticket.support_member.picture
                            }
                          />
                          <h4>
                            {props.singleTicket.data.ticket.support_member.name}
                          </h4>
                          {remoteUsers.length > 0 &&
                            remoteUsers.map((user) => (
                              <AgoraMediaPlayer
                                audioTrack={user.audioTrack}
                                useClassname={"d-none"}
                              ></AgoraMediaPlayer>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              
            </Row>
          </Container>
          
        
      </div>
      
    </>
  );
};

const mapStateToPros = (state) => ({
  singleTicket: state.privateCall.singleTicket,
  endTicket:state.privateCall.endTicket
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AudioCallIndex));

import React from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";

const AgendaModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center agenda-modal"
        size="lg"
        centered
        show={props.agenda}
        onHide={props.closeAgendaModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="agenda-sec">
            <p
              dangerouslySetInnerHTML={{
                __html: props.agendaDetails,
              }}>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AgendaModal;
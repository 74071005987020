import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { apiConstants } from "./components/Constant/constants";

var firebaseConfig = {
    apiKey: apiConstants.firebase_api_key,
    authDomain: apiConstants.firebase_auth_domain,
    projectId: apiConstants.firebase_project_id,
    storageBucket: apiConstants.firebase_storage_bucket,
    messagingSenderId: apiConstants.firebase_messaging_sender_id,
    appId: apiConstants.firebase_app_id,
    measurementId: apiConstants.firebase_measurement_id,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

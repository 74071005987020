import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";

import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  endTicketCallSuccess,
  endTicketCallFailure,
  fetchTicketDetailsSuccess,
  fetchTicketDetailsFailure,
  fetchEventSuccess,
  fetchEventFailure,
  endEventSuccess,
  endEventFailure,
  fetchEventUsersSuccess,
  fetchEventUsersFailure,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
} from "../actions/PrivateCallAction";
import {
  END_TICKET_CALL_START,
  FETCH_TICKET_DETAILS_START,
  FETCH_EVENT_DETAILS_START,
  END_EVENT_START,
  FETCH_EVENT_USERS_START,
  FETCH_MORE_EVENT_USERS_START,
  FETCH_USER_DETAILS_START,
} from "../actions/ActionConstant";

function* fetchTicketDetailsAPI(action) {
  try {
    const response = yield api.postMethod(
      "tickets_view",
      action.data
    );
    if (response.data.success) {
      yield put(fetchTicketDetailsSuccess(response.data.data));
    } else {
      yield put(fetchTicketDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTicketDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* endTicketCallApi(action) {
  try {
    const response = yield api.postMethod("tickets_complete", action.data);
    if (response.data.success) {
      yield put(endTicketCallSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      // window.location.reload();
    } else {
      yield put(endTicketCallFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(endTicketCallFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchEventAPI(action) {
  try {
    const response = yield api.postMethod(
      "events_view",
      action.data
    );
    if (response.data.success) {
      yield put(fetchEventSuccess(response.data.data));
    } else {
      yield put(fetchEventFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchEventFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* endEventAPI(action) {
  try {
    const response = yield api.postMethod("events_end", action.data);
    if (response.data.success) {
      yield put(endEventSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      // window.location.reload();
    } else {
      yield put(endEventFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(endEventFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchEventUsersAPI(action) {
  try {
    const response = yield api.postMethod(
      "events_user_web",
      action.data
    );
    if (response.data.success) {
      yield put(fetchEventUsersSuccess(response.data.data));
    } else {
      yield put(fetchEventUsersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchEventUsersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod(
      "user_details",
      action.data
    );
    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data.data));
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_TICKET_DETAILS_START, fetchTicketDetailsAPI),]);
  yield all([yield takeLatest(END_TICKET_CALL_START, endTicketCallApi)]);
  yield all([yield takeLatest(FETCH_EVENT_DETAILS_START, fetchEventAPI)]);
  yield all([yield takeLatest(END_EVENT_START, endEventAPI)]);
  yield all([yield takeLatest(FETCH_EVENT_USERS_START, fetchEventUsersAPI)]);
  yield all([yield takeLatest(FETCH_MORE_EVENT_USERS_START, fetchEventUsersAPI)]);
  yield all([yield takeLatest(FETCH_USER_DETAILS_START, fetchUserDetailsAPI)]);
}


export const FETCH_TICKET_DETAILS_START = "FETCH_TICKET_DETAILS_START";
export const FETCH_TICKET_DETAILS_SUCCESS = "FETCH_TICKET_DETAILS_SUCCESS";
export const FETCH_TICKET_DETAILS_FAILURE = "FETCH_TICKET_DETAILS_FAILURE";

export const END_TICKET_CALL_START = "END_TICKET_CALL_START";
export const END_TICKET_CALL_SUCCESS = "END_TICKET_CALL_SUCCESS";
export const END_TICKET_CALL_FAILURE = "END_TICKET_CALL_FAILURE";

export const FETCH_EVENT_DETAILS_START = "FETCH_EVENT_DETAILS_START";
export const FETCH_EVENT_DETAILS_SUCCESS = "FETCH_EVENT_DETAILS_SUCCESS";
export const FETCH_EVENT_DETAILS_FAILURE = "FETCH_EVENT_DETAILS_FAILURE";

export const END_EVENT_START = "END_EVENT_START";
export const END_EVENT_SUCCESS = "END_EVENT_SUCCESS";
export const END_EVENT_FAILURE = "END_EVENT_FAILURE";

export const FETCH_EVENT_USERS_START = "FETCH_EVENT_USERS_START";
export const FETCH_EVENT_USERS_SUCCESS = "FETCH_EVENT_USERS_SUCCESS";
export const FETCH_EVENT_USERS_FAILURE = "FETCH_EVENT_USERS_FAILURE";
export const FETCH_MORE_EVENT_USERS_START = "FETCH_MORE_EVENT_USERS_START";

export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

import React, { useRef, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";

const AgoraAudioCall = (props) => {
    const container = useRef(null);


    useEffect(() => {
        if (!container.current) return;
        if (props.videoTrack) {
            props.videoTrack.play(container.current, {
                mirror: props.mirror ? true : false,
            });
            return () => {
                props.videoTrack.stop();
            };
        }
    }, [container, props.videoTrack]);
    useEffect(() => {
        if (props.audioTrack) {
            props.audioTrack.play();
            return () => {
                props.audioTrack.stop();
            };
        }
    }, [props.audioTrack]);

    return (
        <>
            <div className="meeting-room-user-card">
                <Image
                    className="meeting-room-user-img"
                    src={props.user.user_picture ?? window.location.origin + "/img/placeholder.png"}
                />
                <div className="meeting-user-name">
                    <span>{props.user.user_name ?? ''}</span>
                </div>
                {props.audioTrack ?
                    <div className="meeting-user-mute-bg">
                        <Image
                            className="meeting-user-mute-icon"
                            src={
                                window.location.origin + "/img/action-mute-icon-1.svg"
                            }
                        />
                    </div>
                    :
                    <div className="meeting-user-unmute-bg">
                        <Image
                            className="meeting-user-unmute-icon"
                            src={
                                window.location.origin + "/img/action-unmute-icon-1.svg"
                            }
                        />
                    </div>
                }
            </div>
        </>
    );
};

export default AgoraAudioCall;

import {
    FETCH_TICKET_DETAILS_START,
    FETCH_TICKET_DETAILS_FAILURE,
    FETCH_TICKET_DETAILS_SUCCESS,
    END_TICKET_CALL_SUCCESS,
    END_TICKET_CALL_FAILURE,
    END_TICKET_CALL_START,
    FETCH_EVENT_DETAILS_START,
    FETCH_EVENT_DETAILS_SUCCESS,
    FETCH_EVENT_DETAILS_FAILURE,
    END_EVENT_START,
    END_EVENT_SUCCESS,
    END_EVENT_FAILURE,
    FETCH_EVENT_USERS_START,
    FETCH_EVENT_USERS_SUCCESS,
    FETCH_EVENT_USERS_FAILURE,
    FETCH_MORE_EVENT_USERS_START,
    FETCH_USER_DETAILS_START,
    FETCH_USER_DETAILS_SUCCESS,
    FETCH_USER_DETAILS_FAILURE,
  } from "./ActionConstant";

  export function fetchTicketDetailsStart(data) {
    return {
      type: FETCH_TICKET_DETAILS_START,
      data,
    };
  }
  
  export function fetchTicketDetailsSuccess(data) {
    return {
      type: FETCH_TICKET_DETAILS_SUCCESS,
      data,
    };
  }
  
  export function fetchTicketDetailsFailure(error) {
    return {
      type: FETCH_TICKET_DETAILS_FAILURE,
      error,
    };
  }
  
  export function endTicketCallStart(data) {
    return {
      type: END_TICKET_CALL_START,
      data,
    };
  }
  
  export function endTicketCallSuccess(data) {
    return {
      type: END_TICKET_CALL_SUCCESS,
      data,
    };
  }
  
  export function endTicketCallFailure(error) {
    return {
      type: END_TICKET_CALL_FAILURE,
      error,
    };
  }
 
  export function fetchEventStart(data) {
    return {
      type: FETCH_EVENT_DETAILS_START,
      data,
    };
  }
  
  export function fetchEventSuccess(data) {
    return {
      type: FETCH_EVENT_DETAILS_SUCCESS,
      data,
    };
  }
  
  export function fetchEventFailure(error) {
    return {
      type: FETCH_EVENT_DETAILS_FAILURE,
      error,
    };
  }
  export function endEventStart(data) {
    return {
      type: END_EVENT_START,
      data,
    };
  }
  
  export function endEventSuccess(data) {
    return {
      type: END_EVENT_SUCCESS,
      data,
    };
  }
  
  export function endEventFailure(error) {
    return {
      type: END_EVENT_FAILURE,
      error,
    };
  }

  export function fetchEventUsersStart(data) {
    return {
      type: FETCH_EVENT_USERS_START,
      data,
    };
  }
  
  export function fetchEventUsersSuccess(data) {
    return {
      type: FETCH_EVENT_USERS_SUCCESS,
      data,
    };
  }
  
  export function fetchEventUsersFailure(error) {
    return {
      type: FETCH_EVENT_USERS_FAILURE,
      error,
    };
  }
 
  export function fetchMoreEventUsersStart(data) {
    return {
      type: FETCH_MORE_EVENT_USERS_START,
      data,
    };
  }

  export function fetchUserDetailsStart(data) {
    return {
      type: FETCH_USER_DETAILS_START,
      data,
    };
  }
  
  export function fetchUserDetailsSuccess(data) {
    return {
      type: FETCH_USER_DETAILS_SUCCESS,
      data,
    };
  }
 
  export function fetchUserDetailsFailure(error) {
    return {
      type: FETCH_USER_DETAILS_FAILURE,
      error,
    };
  }
import React, { Component } from "react";
import { Notify } from "react-redux-notify";
import EmptyHeader from "./Header/EmptyHeader";

const EmptyLayout = (props) => {
  return (
      <>
        <Notify position="TopRight" />
        {/* <EmptyHeader /> */}
        <div className="main-wrap-sec">
          {React.cloneElement(props.children)}
        </div>
      </>
    );
  };
  
  export default EmptyLayout;

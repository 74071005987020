import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./VideoAudioCall.css";
import { Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgoraRTC from "../../hooks/useAgoraRTC";
import {
  fetchTicketDetailsStart,
  endTicketCallStart,
} from "../../store/actions/PrivateCallAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import AgoraMediaPlayer from "../helper/AgoraMediaPlayer";
import io from "socket.io-client";

let callSocket;
const rtcclient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const VideoCallIndex = (props) => {
  const {
    localAudioTrack,
    localVideoTrack,
    leaveRtcChannel,
    join,
    joinState,
    remoteUsers,
    isStreamEnded,
    muteAudio,
    muteVideo,
    mediaStatus,
  } = useAgoraRTC(rtcclient);

  useEffect(() => {
    props.dispatch(
      fetchTicketDetailsStart({
        ticket_unique_id:props.match.params.ticket_unique_id,
      })
    );
  }, []);

  const [skipRenderRes, setSkipRenderRes] = useState(true);

  useEffect(() => {
    if (!props.singleTicket.loading) {
      handleJoin();
      callSocketConnect();
    }
  }, [props.singleTicket.loading]);

  useEffect(() => {
    if (joinState) {
      
    }
  }, [joinState]);

  const handleJoin = () => {
    join(
      configuration.get("configData.agora_app_id"),
      props.singleTicket.data.ticket.virtual_id,
      props.singleTicket.data.ticket.agora_token
        ? props.singleTicket.data.ticket.agora_token
        : null,
      "host",
      "rtc"
    );
  };

  const callSocketConnect = () => {
    // check the socket url is configured
    let socketUrl = configuration.get("configData.chat_socket_url");
    console.log(socketUrl);
    if (socketUrl && props.singleTicket.data.ticket) {
     
      callSocket = io(socketUrl, {
        query: `commonid:'user_id_${props.singleTicket.data.ticket.user_id}_ticket_id_${props.singleTicket.data.ticket.ticket_id}',myid:`+props.singleTicket.data.ticket.user_id,
      });

      callSocket.emit("agora call update sender", {
        commonid: `user_id_${props.singleTicket.data.ticket.user_id}_ticket_id_${props.singleTicket.data.ticket.ticket_id}`,
      });

      callSocket.on("end agora call", (newData) => {
        console.log(newData);
        props.dispatch(
          endTicketCallStart({
            ticket_unique_id: props.match.params.ticket_unique_id,
          })
        );
      });

    }
  };
  
  const leaveCall = () => {
    if (window.confirm("are you sure to leave call?")) {
      leaveRtcChannel();
      props.dispatch(
        endTicketCallStart({
          ticket_unique_id: props.match.params.ticket_unique_id,
        })
      );
    } else {
    }
  };

  useEffect(() => {
    if (!skipRenderRes && !props.endTicket.loading && Object.keys(props.endTicket.data).length > 0) {
      
      const timeout = setTimeout(() => {
        window.location.replace(props.endTicket.data.redirection_url);
      }, 3000);
  
      return () => clearTimeout(timeout);

    }
    setSkipRenderRes(false);
  }, [props.endTicket]);
  
  useEffect(() => {
    if (isStreamEnded) {
      props.dispatch(
        endTicketCallStart({
          ticket_unique_id: props.match.params.ticket_unique_id,
        })
      );
    }
  }, [isStreamEnded]);
 
  if (props.singleTicket.loading) {
    return null;
  }

  return (
    <>
      <div className="video-call-sec">
        {/* <div className="video-call-mobile-sec">
          <Link to="#" onClick={() => setMobileVideoAudioChat(true)}>
            <div className="live-streaming-comments-mobile-display">
              <Button className="view-comments-btn">View Chat</Button>
            </div>
          </Link>
        </div> */}
        <div className="video-call-box justify-content-center row m-0">
          <div className="video-call-card-left col-lg-12 col-md-12 p-0">
            {joinState && (
              <div className="video-call-card">
                {remoteUsers.length > 0 ? (
                  remoteUsers.map((user) => (
                    <div className="video-call-user-img-sec">
                      {user._video_muted_ ? (
                        <Image
                          src={props.singleTicket.data.ticket.user.picture
                          }
                          alt="action-icons"
                          className="video-call-bg-img"
                        />
                      ) : (
                        <AgoraMediaPlayer
                          videoTrack={user.videoTrack}
                          audioTrack={user.audioTrack}
                          useClassname={"video-call-bg-img"}
                        ></AgoraMediaPlayer>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="video-call-user-img-sec">
                    <Image
                      src={props.singleTicket.data.ticket.user.picture}
                      alt="action-icons"
                      className="video-call-bg-img"
                    />
                  </div>
                )}

                <div className="video-call-user-img-sec">
                  {mediaStatus.video.muted ? (
                    <Image
                      src={
                        props.singleTicket.data.ticket.support_member.picture       
                      }
                      alt="action-icons"
                      className="video-call-user-img"
                    />
                  ) : (
                    <AgoraMediaPlayer
                      videoTrack={localVideoTrack}
                      useClassname={"video-call-user-img"}
                      mirror
                    ></AgoraMediaPlayer>
                  )}
                </div>
                <div className="video-call-action-btn-sec">
                  <ul className="list-unstyled video-call-action-btn">
                    <Media as="li">
                      <Button className="btn-link" onClick={() => muteVideo()}>
                        {mediaStatus.video.muted ? (
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/video-call/camera-off.svg"
                            }
                            alt="action-icons"
                            className="action-icon-img"
                          />
                        ) : (
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/video-call/camera.svg"
                            }
                            alt="action-icons"
                            className="action-icon-img"
                          />
                        )}
                      </Button>
                    </Media>
                    <Media as="li" className="call-animation">
                      <Button className="btn-link" onClick={() => leaveCall()}>
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/video-call/end-call.svg"
                          }
                          alt="action-icons"
                          className="action-icon-img"
                        />
                      </Button>
                    </Media>
                    <Media as="li">
                      <Button className="btn-link" onClick={() => muteAudio()}>
                        {mediaStatus.audio.muted ? (
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/video-call/mic-off.svg"
                            }
                            alt="action-icons"
                            className="action-icon-img"
                          />
                        ) : (
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/video-call/mic.svg"
                            }
                            className="action-icon-img"
                          />
                        )}
                      </Button>
                    </Media>
                  </ul>
                </div>
              </div>
            )}
          </div>
          
        </div>
      </div>
      
    </>
  );
};

const mapStateToPros = (state) => ({
  singleTicket: state.privateCall.singleTicket,
  endTicket:state.privateCall.endTicket
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VideoCallIndex));
